<template>
  <b-container class="bv-example-row mb-3" fluid>
    <hr/>
    <b-card>
      <b-row class="mt-1">
        <b-col cols="6">
          <h2>Cari Kart Listesi</h2>
        </b-col>
        <b-col cols="6 justify-content-end d-flex">
          <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="info"
              class="myButton mr-50"
              @click="$router.go(-1)"
          >
            <feather-icon icon="ChevronLeftIcon" class="mr-50"/>
            Geri Dön
          </b-button>
        </b-col>
      </b-row>
      <hr/>
      <b-row>
        <b-col sm="3" md="3" class="my-1">
          <b-input-group class="showRecordCount">
            <b-input-group size="sm" prepend="Gösterilecek kayıt sayısı">
              <b-form-select
                  id="per-page-select"
                  v-model="perPage"
                  :options="pageOptions"
                  size="sm"
              >
              </b-form-select>
            </b-input-group>
          </b-input-group>
        </b-col>
        <b-col lg="3" class="my-1">
          <b-form-group
              label="Ara"
              label-for="filter-input"
              label-cols-sm="3"
              label-align-sm="right"
              class="mb-0"
          >
            <b-input-group size="sm">
              <b-form-input
                  id="filter-input"
                  v-model="filter"
                  type="search"
              />
            </b-input-group>
          </b-form-group>
        </b-col>
      </b-row>
      <b-table
          striped
          hover
          responsive
          :items="getCariListe"
          :fields="fields"
          :current-page="currentPage"
          :per-page="perPage"
          :filter="filter"
          :filter-included-fields="filterOn"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :sort-direction="sortDirection"
          stacked="md"
          show-empty
          empty-text="Veri Bulunamadı"
          small
          @filtered="onFiltered"
          table-class=""
      >
        <template #cell(faturaUnvan)="row">
          <div class="row">
            <div class="col-12">
              <p class="text-primary unvan-text" style="font-weight: bold">
                {{ row.item.faturaUnvan }}
              </p>
            </div>
            <div class="col-12">
              <b-badge variant="light-primary" class="mr-1">
                <p class="text-primary" style="font-weight: bold">
                  {{ row.item.adi }} {{ row.item.soyAdi }}
                </p>
              </b-badge>
            </div>
          </div>
        </template>
        <template #cell(adi)="row">
          <div class="row">
            <div class="col-12">
              <p class="text-primary" style="font-weight: bold">
                {{ row.item.adi }}
              </p>
            </div>
          </div>
        </template>
        <template #cell(tcKimlik)="row">
          <p class="text-primary" style="font-weight: bold">
            {{ row.item.tcKimlik }}
          </p>
        </template>
        <template #cell(telefon)="row">
          <p
              v-if="row.item.telefon"
              class="text-primary"
              style="font-weight: bold"
          >
            <feather-icon icon="PhoneIcon" class="mr-50"/>
            {{ row.item.telefon }}
          </p>
        </template>
        <template #cell(cariozet)="row">
          <p style="font-weight: bold; font-size: 17px">
            {{ row.item.cariOzet.bakiye }} TL
          </p>
          <p
              :style="{
                color:
                  row.item.cariOzet.bakiye == 0
                    ? '#878b90'
                    : row.item.cariOzet.bakiye > 0
                    ? 'orange'
                    : 'red',
              }"
              style="font-weight: bold"
          >
            {{ bakiyeControl(row.item.cariOzet.bakiye) }}
          </p>
        </template>
      </b-table>
      <hr/>
      <b-row cols="6" class="my-row">
        <b-col cols="6">
          <p class="text-muted">Toplam {{ totalRows }} kayıt gösteriliyor</p>
        </b-col>
        <b-col cols="6">
          <b-pagination
              align="end"
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              size="sm"
              class="my-0"
          />
        </b-col>
      </b-row>
    </b-card>
  </b-container>
</template>

<script>
import {
  BRow,
  BCol,
  BContainer,
  BTable,
  BBadge,
  BButton,
  BCard,
  BFormInput,
  BInputGroup,
  BFormGroup,
  BFormSelect,
  BPagination,
  VBModal,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BRow,
    BCol,
    BContainer,
    BTable,
    BBadge,
    BButton,
    BCard,
    BFormInput,
    BInputGroup,
    BFormGroup,
    BFormSelect,
    BPagination,
  },
  data() {
    return {
      spinner: true,
      fields: [
        {
          key: 'faturaUnvan',
          label: 'ŞİRKET ADI',
          sortable: true,
          sortDirection: 'desc',
          sortByFormatted: true,
          filterByFormatted: true,
          thStyle: { width: '25%' },
        }, {
          key: 'adi',
          label: 'YETKİLİ',
          sortable: true,
          sortDirection: 'desc',
          sortByFormatted: true,
          filterByFormatted: true,
          thStyle: { width: '20%' },
        }, {
          key: 'telefon',
          label: 'TELEFON',
          sortable: true,
          sortByFormatted: true,
          filterByFormatted: true,
          thStyle: { width: '10%' },
        },
        {
          key: 'tcKimlik',
          label: 'TC KİMLİK / VERGİ NO',
          sortable: true,
          sortByFormatted: true,
          filterByFormatted: true,
          thStyle: { width: '20%' },
        }, {
          key: 'cariOzet',
          label: 'BAKİYE',
          sortable: true,
          sortByFormatted: true,
          filterByFormatted: true,
          thStyle: { width: '10%' },
        },
      ],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [
        5,
        10,
        20,
        25,
        50,
        100,
        {
          value: 100,
          text: 'Tümünü Göster'
        },
      ],
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      choose: [],
      newCariInput: {
        isim: '',
        soyAdi: '',
        adres: '',
        posta: '',
        gsm: '',
        tel: '',
        firmaUnvan: '',
        vergiNo: '',
        vergiDairesi: '',
      },
    }
  },
  computed: {
    getCariListe() {
      return Object.values(this.$store.getters.getCariListe)
    },
    sortOptions() {
      this.fields
          .filter((f) => f.sortable)
          .map((f) => {
            return {
              text: f.label,
              value: f.key
            }
          })
    },
  },
  methods: {
    bakiyeControl(bakiye) {
      return bakiye == 0 ? 'Bakiye Yok' : bakiye > 0 ? 'Borçlu' : 'Alacaklı'
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
  },
  created() {
    this.$store
        .dispatch('cariKart')
        .then((res, position = 'bottom-right') => {
          this.totalRows = Object.values(res.cariListe).length
          const mesaj = this.$store.getters.notificationSettings(res)
          if (mesaj) {
            this.$toast(
                {
                  component: ToastificationContent,
                  props: {
                    title: 'Başarılı',
                    text: mesaj,
                    icon: 'ThumbsUpIcon',
                    variant: 'success',
                  },
                },
                { position }
            )
          }
        })
        .catch((err) => {
          const mesaj = this.$store.getters.notificationSettings(err)
          if (mesaj) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Hata!',
                text: mesaj,
                icon: 'ThumbsDownIcon',
                variant: 'danger',
              },
            })
          }
        })
  },
  beforeDestroy() {
    this.$store.commit('cariListAction', 'remove')
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
}
</script>
<style scoped>
.spinners {
  position: absolute;
  margin-left: 47%;
  margin-top: 15%;
  width: 3rem;
  height: 3rem;
}

.mr-12 {
  margin-right: 12px !important;
  background-color: #2e83c9 !important;
}

.myHr {
  width: 60% !important;
}

.bg-primaryy {
  background-color: rgb(49 82 111) !important;
}

.badge-primary {
  background-color: rgba(115, 103, 240, 0.12);
  color: #7367f0 !important;
}

.spinners {
  position: absolute;
  margin-left: 47%;
  margin-top: 15%;
  width: 3rem;
  height: 3rem;
}

.myButton {
  color: white !important;
}

.myButton:hover {
  color: white !important;
}

a {
  color: #408dcc !important;
}

a:hover {
  color: #6d62e4 !important;
}

.card-header {
  background-color: #2e83c9;
  height: 30px;
  position: relative;
}

.card-header h4 {
  position: absolute;
  color: white;
  margin-top: 10px;
}

.row {
  justify-content: space-between;
}

.my-row {
  margin-top: 25px;
}

.card-body {
  padding: 10px 15px;
}

.b-panination {
  transform: translateY(-31px);
}

.input-group:not(.bootstrap-touchspin):focus-within {
  box-shadow: none;
  border-radius: 0.357rem;
}

.custom-select {
  max-width: 110px !important;
}

p {
  margin-top: 0;
  margin-bottom: 0;
}

.badge {
  padding: 0.1rem 0.4rem;
  font-size: 70%;
}

.unvan-text {
  font-size: 13px !important;
}

.modalBadge {
  padding: 0.3rem 0.8rem;
  font-size: 70%;
}
</style>
<style>

.table:not(.table-dark):not(.table-light) thead:not(.thead-dark) th,
.table:not(.table-dark):not(.table-light) tfoot:not(.thead-dark) th {
  background-color: #009879;
  color: white;
}
</style>
<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
